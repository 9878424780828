/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  MdOutlinePauseCircle, MdOutlinePlayCircle } from "react-icons/md";

import { BaseBoardSize } from '../../constants/gameConstants'
import Spinner from '../../UI-components/Spinners/Spinner'
import BGBoard from '../../UI-components/BgBoard/Bg-board'
import { I18n } from '../../assets/i18n'
import { AnimatedBoard } from '../../UI-components/Board/AnimatedBoard'
import { selectWindowSize } from '../../store/topStateSlice';
import { selectUserWithProps } from '../../store/rootState&Reducer';

import './about.scss'


export const Home = () => {
    const {
        viewMode: mode, theme, language
    } = useSelector(selectUserWithProps(['viewMode', 'theme', 'language']))
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    const [cS1, setSc1] = useState(0)
    const winSize = useSelector(selectWindowSize)
    useEffect(() => {
        if (winSize.width < 560) {
            cS1 !== 35 && setSc1(35)
        } else if (winSize.width >= 560 && winSize.width < 960) {
            cS1 !== 30 && setSc1(30)
        } else if (winSize.width >= 960 && winSize.width < 1200) {
            cS1 !== 40  && setSc1(40)
        } else if (winSize.width >= 1200) {
            cS1 !== 45 && setSc1(45)
        }           
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [winSize])
    
       
    const ExampleGame = () => {
        const [pause, setPause] = useState(false)
        return (
            <button
                type='button' 
                className="game-example-wrapper"
                onClick={() => setPause(!pause)}
            >
                <AnimatedBoard stop={pause} cS={cS1} />
                <div className='example-ctrl-btn'>
                    {!pause ? <MdOutlinePauseCircle /> : <MdOutlinePlayCircle />}
                </div>
            </button>
        )
    }
   
    
        
    return (
        <Suspense fallback={<Spinner />}>
            <div className={`page-wrapper noselect dh-${cS1 * BaseBoardSize}`}>
                <BGBoard />
                <div className={`about-page mode-${mode} theme-${theme}`}>
                    
                    <h1>{i18n.about_title || i18n.en.about_title}</h1>
                    <div className='description-wrapper'>
                        <ExampleGame />
                        <div className={`game-description`}>{i18n.about_start}</div>
                    </div>
                </div>
            </div>
        </Suspense>
    )
}

export default Home
