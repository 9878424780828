import { useDispatch, useSelector } from "react-redux"
import { selectPuzzle, selectUserWithProps, selectWithProps } from "../../store/rootState&Reducer"
import GameBoard from "../Game/GameBoard"
import { useNavigate } from "react-router-dom"
import { PuzzleMenu } from "./PuzzleMenu"
import { choosePuzzle } from "../../store/topStateSlice"
import { TopState } from "../../constants/gameConstants"
import { GameVar, IPuzzle, ITopState } from "../../models/models"
import { useEffect, useState } from "react"
import { I18n } from "../../assets/i18n"
import { CgMenuGridR } from "react-icons/cg";
import { TbPuzzle } from "react-icons/tb"
import { BlackTower, WhiteTower } from "../../UI-components/Tower/GamePiece"

export const Puzzle = () => {
    const {
        viewMode: mode, theme, name, exp: {onboardingPassed}, language
    } = useSelector(selectUserWithProps(['viewMode', 'theme', 'exp', 'name', 'language']))
    const {
        boardSize, cellSize
    } = useSelector(selectWithProps(['boardSize', 'cellSize'], 'board'))
    const {puzzleResolved} = useSelector(selectWithProps(['puzzleResolved'], TopState)) as ITopState
    const i18n = I18n[language.slice(0, 2)] || I18n.en
   
    const puzz = useSelector(selectPuzzle)
    const [puzzle, setPuzzle] = useState(puzz as IPuzzle)
    const [title, setTitle] = useState(puzzle.description)
    const [type, setType] = useState('')
    useEffect(()=> {
        if (puzz) {
            setPuzzle(puzz)
        }
    }, [puzz])
   
    
    useEffect(() => {
        setType(i18n[GameVar[puzzle.gType as 'tow']])
        setTitle(i18n[puzzle.description.split(' ').slice(0, 2).join('_')])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puzzle, language])
    useEffect(() => {
        if (!puzzleResolved || onboardingPassed) return
        const randInd = Math.floor(Math.random()*i18n.puzzleCongrats.length)
        const congrats =  `${i18n.puzzleCongrats[randInd]} ${name}!`
        const oops = `${i18n.oops}`
        setType(puzzleResolved.resolved ? congrats : oops)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puzzleResolved])

    const bW = Math.min(cellSize*boardSize, 600)
    const Tower = () => puzzle.description.includes('White') ? <WhiteTower /> : <BlackTower />
    const success = !onboardingPassed && puzzleResolved?.resolved ? ' success' : ''
    return (        
        <div className={`puzzle-page bw-${bW} mode-${mode} theme-${theme}`}>
            <header className={`puzzle-header`}>
                <div className={`game-variant${success}`}>
                    {type}
                </div>
                <div className="puzzle-title-wrapper">
                    <div className="puzzle-title">
                        <span className="puzzle-title_piece-box"><Tower /></span>
                        <span className="puzzle-title_text">{title}</span>
                    </div> 
                   <PuzzleMenu />
               
                </div>
            </header>
            
            <div className="puzzle-page__content">
                <div className={`puzzle-main bw-${bW} mode-${mode} theme-${theme}`}>
                    <GameBoard />
                </div>
            </div>
        </div>
    )
}
