import {call, delay, put, select, takeLatest} from 'redux-saga/effects'
import { UnknownAction } from 'redux'

import basicPuzzles from '../../assets/basicPuzzles.json'
import {
    InitialAnalysisState, 
    setPosition, 
    updateAnalysisState,
} from '../analysisSlice'
import {moveResolver as moveR} from '../../local-engine/move-resolver'
import { 
    InitialGame, 
    updateGame,
    updatePieces,
    updatePosition, 
} from '../gameSlice'
import { 
    getPuzzles, setAppType, setBackMessage, setPuzzles, choosePuzzle,
    setAppState,
    startEngine,
    savePuzzle,
} from '../topStateSlice'
import { Axios, setAuthorizationHeader } from '../../common/axios'
import { selectPuzzle } from '../rootState&Reducer'
import { turnBoard } from '../boardSlice'
import { createOutBoardTowers, removeOutboardTowers, updatePiecesPosition } from '../../local-engine/board-helper-fn'
import { copyObj, isDev, movesFromSolution, oppositeColor, parsePuzzles, positionWithoutDom } from '../../local-engine/gameplay-helper-fn'
import { IRootState, IPuzzle, IBoardPieces, PieceColor, AppType, GameResult, GameVar } from '../../models/models'
import storage from '../../common/storage'
import { endGame, setGameState, InitialGameState, setGameKey } from '../gameStateSlice'
import { setGameVariant } from '../gameOptionsSlice'

function* workerPuzzle(action: UnknownAction) {
    const {
        user: {token, userId},
        analysis: {bestMoveLines, startTurn},
        game: {moves, startPosition},
        gameSettings: {gameVariant: GV},
        board: {boardSize},
        topState: {selectedPuzzle}
    } = (yield select()) as IRootState
    const {description, level} = action.payload as any
    const payload = {
        position: JSON.stringify(positionWithoutDom(startPosition)), 
        solution: moves.length && moves.length * 2 - 1 > bestMoveLines[0].line.length
            ? moves.reduce((acc: string[], m: any) => {
                if (m.black) {
                    acc.push(m.black.move)
                }
                acc.push(m.white.move)
                return acc
            }, [] as string[]).join('_')
            : bestMoveLines[0].line.join('_'),
        gType: GV.slice(0, 3),
        turn: startTurn,
        author: userId,
        bSize: boardSize,
        description,
        level
    }
    setAuthorizationHeader(token)
    try {
        if (!selectedPuzzle) {
            yield call(Axios.post, '/api/puzzle/new', payload)
        } else {
            (payload as any)._id = selectedPuzzle
            yield call(Axios.post, '/api/puzzle/update', payload)
        }
    }
    catch(e: any) {
        console.log(e)
        const mess = {msg: e.response?.data?.message || e.message, ok: false}
        yield put(setBackMessage(mess))
    }
}

function* workerChoosePuzzle() {
    const {topState: {localEngine}} = (yield select()) as IRootState
    if (localEngine) { 
        yield put(startEngine(false))
    }
    yield delay(0)
    const puzzle: IPuzzle = yield select(selectPuzzle)
    if (!puzzle) return
    const {turn, solution, position: pos, bSize, gType} = puzzle
    const GV = GameVar[gType as 'tow'].toLowerCase()
    yield put(setGameVariant({GV, boardSize: bSize}))
    yield delay(50)
    const {
        board: {cellSize, cellsMap, reversedBoard},
    } = (yield select()) as IRootState
    let position = updatePiecesPosition(pos as IBoardPieces, cellsMap, cellSize)
    yield put(updateAnalysisState({...InitialAnalysisState, startTurn: turn}))
    yield put(setAppState({
        solution: movesFromSolution(solution as string[], position, moveR), 
        puzzleResolved: null
    }))
    const nextMoves = moveR.getPossibleMoves(position, puzzle.turn, true)
    if (nextMoves[0].takenPieces?.length) {
        const posWithMand = copyObj(position)
        for (const move of nextMoves) {
            const key = move.move[0]
            posWithMand[key].mandatory = true
        }
        position = posWithMand
    }
    yield put(updateGame({
        ...InitialGame, position, startPosition: position, nextMoves, turn: puzzle.turn
    }))
    if (reversedBoard !== (puzzle.turn === PieceColor.black)) {
        yield put(turnBoard(puzzle.turn === PieceColor.black))
    }
}

export function* workerGetPuzzles(action: UnknownAction) {
    const {
        topState: {puzzles = []}, 
        user: {
            token,
            exp: {
                tow: {puzzles: tp = 0},
                rus: {puzzles: rp = 0},
                int: {puzzles: ip = 0},
                onboardingPassed
            }, 
            resolvedPuzzles = [],
        },
    } = (yield select()) as IRootState
    let storedPuzzles = storage.getPuzzles()
    if (puzzles.length > 6) {
       return
    }
    if (!storedPuzzles.length && token) {
        try {
            setAuthorizationHeader(token)
            const url = `/api/puzzle?tow=${tp}&int=${ip}&rus=${rp}`
            const res: {data: any} = yield call(Axios.get, url)
            storedPuzzles = parsePuzzles(res.data.puzzles)
            storage.savePuzzles(storedPuzzles)
        } catch(e: any) {
            console.log(e)
            const mess = {msg: e.response?.data?.message || e.message, ok: false}
            yield put(setBackMessage(mess))
            yield put(setPuzzles(puzzles))
            return
        }
    }
    const saved = storedPuzzles.length ? storedPuzzles : basicPuzzles as unknown as IPuzzle[]
    const unresolved = saved.filter(m => !resolvedPuzzles.includes(m._id))
        .sort((a: IPuzzle, b: IPuzzle) => a.level - b.level)
    yield put(setPuzzles(unresolved.slice(0, onboardingPassed ? 6 : unresolved.length)))
}

function* workerAppType(action: UnknownAction) {
    const type = action.payload
    let {
        gameState: {result = null, gameKey, playerColor},
    } = (yield select()) as IRootState
    if (gameKey && !result && type !== AppType.game) {
        result = GameResult[oppositeColor(playerColor)]
        yield put(endGame({game: {result}}))
        yield put(setGameKey(''))
        yield delay(50)
    }
    const {
        topState: {localEngine},
        analysis: {settingPosition},
        user: {token}
    } = (yield select()) as IRootState
    if (!settingPosition) {
        yield put(setPosition(true))
    }
    if (action.payload !== AppType.game && localEngine) {
        yield put(startEngine(false))
    }
    if (!settingPosition || localEngine) yield delay(50)
    const {game: {position: pos},
        board: {cellSize, cellsMap, boardSize},
        topState: {puzzles}
    } = (yield select()) as IRootState
    switch (type) {
        case AppType.game: {
            if (!gameKey) {
                yield put(updateGame(InitialGame))
                yield put(updateAnalysisState(InitialAnalysisState))
                yield put(setGameState(InitialGameState))
            }
            break
        }
        case AppType.puzzles: {
            if (puzzles.length < 6 && token) {
                yield put(getPuzzles())
            }
            break
        }
        case AppType.analysis: {
            if (result) {
                yield put(setPosition(false))
            } else {
                yield delay(200)
                const position = updatePiecesPosition(
                    createOutBoardTowers(copyObj(pos), boardSize), cellsMap, cellSize
                )
                if (Object.values(position)[0] && Object.values(position)[0].DOM?.x === 0) {
                    yield delay(100)
                }
                yield put(updatePosition(position))
            }
            break
        }
        default: {
            isDev() && console.log('unexpected app type')
        }
    }
}

function* workerEvaluatePosition(action: UnknownAction) {
    const {
        topState: {localEngine},
        game: {position: pos, startPosition, turn, moves = []},
        gameState: {result}
    } = (yield select()) as IRootState
    
    if (localEngine) { 
        yield put(startEngine(false))
        yield delay(0)
    }
    if (action.payload) {
        const {
            analysis: {unused},
        } = (yield select()) as IRootState
        yield put(updateGame({...InitialGame, position: pos, turn}))
        yield put(updatePieces(unused))
        yield put(updateAnalysisState(InitialAnalysisState))
        return
    }
    const {
        board: {boardSize: size, cellSize, cellsMap},
        gameSettings: {gameVariant: GV},
    } = (yield select()) as IRootState
    moveR.setProps({size, GV})
    const {unused, position: newPos} = removeOutboardTowers(pos)
    let position = updatePiecesPosition((result ? startPosition : newPos), cellsMap, cellSize)
    const nextMoves = moveR.getPossibleMoves(position, turn, true)
    if (nextMoves[0].takenPieces?.length) {
        const posWithMand = copyObj(position)
        for (const move of nextMoves) {
            const key = move.move[0]
            posWithMand[key].mandatory = true
        }
        position = posWithMand
    }
    yield put(updateGame({
        ...InitialGame,
        position,
        startPosition: position,
        turn,
        moves, 
        nextMoves
    }))
    yield put(updateAnalysisState({
        ...InitialAnalysisState,
        unused: updatePiecesPosition(unused, cellsMap, cellSize),
        startTurn: result ? PieceColor.white : turn,
        gameMoves: moves,
        settingPosition: false
    }))
    yield delay(0)
    yield put(startEngine(true))
}

export default function* watcherPuzzles() {
    yield takeLatest(getPuzzles, workerGetPuzzles)
    yield takeLatest(savePuzzle, workerPuzzle)
    yield takeLatest(choosePuzzle, workerChoosePuzzle)
    yield takeLatest(setPosition, workerEvaluatePosition)
    yield takeLatest(setAppType, workerAppType)
}
