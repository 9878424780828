import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import React from 'react'

import {
    IRef,
} from '../../models/models'
import {
    getXY,
    isDev,
} from '../../local-engine/gameplay-helper-fn'
import { Tower } from '../../UI-components/Tower/GamePiece'
import { BoardComponent } from '../../UI-components/Board/Board'
import { useHandler } from '../../hooks/useHandlers'
import { selectPositionWithoutDom, selectUserWithProps } from '../../store/rootState&Reducer'



export const GameBoard = React.memo(() => {
    const boardRef: IRef<HTMLDivElement> = useRef(null)
    const handler = useHandler()
    // const updateBoard = useUpdateBoard(boardRef.current as unknown as HTMLElement)
    const {
        viewMode: mode,
        theme,
    } = useSelector(selectUserWithProps(["viewMode", "theme"]))
    const position = useSelector(selectPositionWithoutDom)

    const handleMouseUp = (event: any) => {
        const { clientX: x, clientY: y } = getXY(event)
        handler.onMouseUp({x, y})
    }

    const handleMouseMove = (event: any) => {
        const { clientX: x, clientY: y } = getXY(event)
        handler.onMouseMove({x, y} as any)
    }

    const handleMouseDown = (event: any) => {
        const { target, clientX: x, clientY: y } = getXY(event)
        const key = (target as HTMLDivElement).getAttribute('data-indexes') as string
        if (key) handler.onMouseDown({key, x, y})
    } 

    const WrapperClass = `board__wrapper mode-${mode} theme-${theme} noselect`
    const Towers = []
    for (const key in position) {
        Towers.push (
            <Tower key={key} posKey={key} />
        )
    }
    return (
        <>
            <section
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
                onTouchMove={handleMouseMove}
                onTouchEnd={handleMouseUp}
                className={WrapperClass}
                ref={boardRef}
            >
                {Towers}
                <BoardComponent />
            </section>
        </>
    )
})

export default GameBoard
