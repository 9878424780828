import React, { useRef, useState } from 'react'
import {connect, ConnectedProps} from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import {IoPersonOutline} from 'react-icons/io5'
import {TbLogin, TbLogout} from 'react-icons/tb'
import { RoutePaths } from '../../constants/gameConstants'
import { logout } from '../../store/userSlice'
import { IRootState } from '../../models/models'
import useCloseEffect from '../../hooks/useCloseEffect'
import { MdOutlinePerson4 } from 'react-icons/md'

const mapState = (state: IRootState) => ({token: state.user.token, name: state.user.name})

const mapDispatch = {logout}

const connector = connect(mapState, mapDispatch)

type Props = ConnectedProps<typeof connector>

const LoginBarComponent = (props: Props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const {logout, token, name} = props
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const isAuth = !!token
    const addListeners = useCloseEffect('user-menu-dropdown-toggle', setDropdownOpen, true)
    const handleLogout = (e: any) => {
        e.preventDefault()
        logout();
        setDropdownOpen(false)
        const prepath = location.pathname.split('/').slice(1).map(n => '../').join('')
        navigate(`${prepath}${RoutePaths.auth}`)
        
    }
    const onClick = () => {
        setDropdownOpen(!dropdownOpen)
        addListeners(true)
    }
    const ref = useRef(null)

    if (isAuth) {
        return (
            <div className='user-menu-wrapper'>
                <button 
                    className="user-menu-dropdown-toggle"
                    type="button" 
                    title="go to profile" 
                    onClick={onClick}
                >
                    {name 
                        ? <span>{name}</span> 
                        : <IoPersonOutline />
                    }
                </button>
                <div className={`user-menu-dropdown${dropdownOpen ? ' open' : ' hidden'}`} >
                    <ul ref={ref}>
                        <li  className="user-menu-profile">
                            <NavLink 
                                title="settings" 
                                to={RoutePaths.profile}
                                className='navlink'
                            >
                                <MdOutlinePerson4 />
                            </NavLink> 
                        </li>
                        <li title="logout" className="user-menu-logout">
                            <button 
                                className='navlink' 
                                type="button" 
                                onClick={handleLogout}
                                onTouchStart={handleLogout}
                            >
                                <TbLogout />
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
    return (
        <div className={`user-menu-wrapper ${name ? '' : ' highlight'}`}>
            <NavLink className={`navlink ${name ? '' : ' highlight'}`} 
                title="login" 
                to={RoutePaths.auth}
            >
                <TbLogin />
            </NavLink> 
        </div>           
    )
}

export const LoginBar = connector(LoginBarComponent)
