/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react'

import './showMessage.scss'
import { MessageFadeTime } from '../constants/gameConstants'
// import { isDev } from '../local-engine/gameplay-helper-fn'
import { IBackMessage } from '../models/models'

export const DefMessage = {msg: '', ok: true} as IBackMessage

export const useMessageShow = (animationLimit = MessageFadeTime) => {
    const [message, setMessage] = useState({...DefMessage}) 
    const tOut = useRef({tOut: null as unknown as ReturnType<typeof setTimeout>})
    function getPopUp (create = true): HTMLElement {
        let popUpElement = document.getElementById('show-message-popup_container')
        if (!popUpElement && create) {
            // isDev() && console.log('not exist', popUpElement)
            const popUpTemplate = `<div id="show-message-popup_container">
                                        <p class="show-message-popup"><p>
                                        <button type=""button class="close-btn">&times;</button>
                                    </div>`
            const root = document.getElementById("root")
            root?.insertAdjacentHTML("afterbegin", popUpTemplate)
            popUpElement = document.getElementById('show-message-popup_container') as HTMLElement
            // isDev() && console.log('popUp',popUpElement)
            popUpElement.addEventListener("click", () => {
                removePopUp(popUpElement!)
            })
            return popUpElement
        }
        return popUpElement as unknown as HTMLElement
    }

    const showMessage = (props: IBackMessage) => {
        setMessage(props)
    }

    const displayShowUp = (popUp: HTMLElement) => {
        (popUp.querySelector('.show-message-popup')! as HTMLElement).innerText = message.msg
        popUp.classList.add('display')
        popUp.classList.add(`${message.ok ? '' : 'error-sh-pop'}`)
    }

    const removePopUp = (popUp: HTMLElement = getPopUp(false)) => {
        if (!popUp) return
        (popUp.querySelector('.show-message-popup')! as HTMLElement).innerText = ''
        !message.ok && popUp.classList.remove(`${'error-sh-pop'}`)
        popUp.classList.remove('display')
        setMessage({...DefMessage})
    }

    // const animateShowUp = (popUp : HTMLElement = getPopUp()) => {
    //     popUp?.classList.add('animate-sh-pop')
    // }

    const showUp = (popUp : HTMLElement = getPopUp()) => {
        displayShowUp(popUp)
        tOut.current.tOut = setTimeout(removePopUp, animationLimit, popUp)
    }

    useEffect(() => {
        if (message.msg) {
            showUp()
        }
        return () => clearTimeout(tOut.current.tOut)
    }, [message.msg])
        
    return {showMessage, message, showUp, fadeMessage: removePopUp}
}
