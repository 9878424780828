import { BoardCl, MPS, TPS } from "../constants/gameConstants";
import { 
    IGame, IBoardPieces, MMRResult, TowerTouched, IBoardTower, PieceColor, PieceType, IBoardPiece, CellsMap, 
    Move
} from "../models/models";

import { calcPiecePosition, getFreeCells, getNumbersOfUnusedTowers } from "./board-helper-fn";
import { checkMoveTargetCell, copyObj, getPiecePossibleMoves } from "./gameplay-helper-fn";

export interface IMMoveProps {
    towerTouched: TowerTouched, 
    event: IHookEvent, 
    position: IBoardPieces
}

export interface IMDownProps {
    cellSize: number,
    cellsMap: CellsMap 
    nextMoves: MMRResult[],
    moveStep: number
    position: IBoardPieces
    event: IHookEvent
    turn: PieceColor
}

export interface IMUpProps {
    cellSize: number,
    cellsMap: CellsMap 
    position: IBoardPieces
    towerTouched: TowerTouched
    nextMoves: MMRResult[],
    moveStep: number
    event: IHookEvent
}

export interface IHookEvent {key: string, x: number, y: number}


export abstract class EventHandlers {
    abstract onMouseDown(props: any): any

    onMouseMove(props: IMMoveProps): any {
        if (!props.towerTouched) {
            return {}
        }
        const {
            key,
            startCursorPosition: SCP,
            startTowerPosition: STP,
        } = props.towerTouched
        const { x, y } = props.event
        const towers = copyObj(props.position)
        const piece = towers[key]! as IBoardTower
        if (!SCP || !STP) {
            console.error('invalid props', props)
        }
        const newPosition = {
            x: STP.x + x - SCP.x,
            y: STP.y + y - SCP.y,
        }
        const prevPosition = piece.DOM
        const deltaX = Math.abs(prevPosition!.x - newPosition.x)
        const deltaY = Math.abs(prevPosition!.y - newPosition.y)
        if (deltaX + deltaY >= 6) {
            const DOM = newPosition
            return {key, DOM}
        }
        return {}
    }
    abstract onMouseUp(props: any): any
}

export const mergeTowers = (
    t1: string,
    t2: string,
    position: IBoardPieces
) => {
    const towers = copyObj(position)
    const tower = {...towers[t1]}
    tower.white += towers[t2].white
    tower.black += towers[t2].black
    tower.color = towers[t2].color
    tower.type = towers[t2].type
    delete towers[t2]
    towers[t1] = tower
    return {towers}
}

class TowersGameHandlers extends EventHandlers {

    onMouseUp(props: IMUpProps) {
        if (!props.towerTouched) {
            return {}
        }
        const boardRect = document.querySelector(`.${BoardCl}`)?.getBoundingClientRect() || {} as any
        const {
            towerTouched, cellSize, cellsMap, position: pos, nextMoves, moveStep: step, event
        } = props
        if (!boardRect.x) return {}
        const possMoves = towerTouched.possibleMoves
        const key = checkMoveTargetCell(event, possMoves, cellSize, boardRect)
        if (!key) {
            const piece = {...pos[towerTouched.key]}
            piece.DOM = calcPiecePosition(towerTouched.key, cellsMap, cellSize)
            // console.log('return', {pieces: {[towerTouched.key]: piece}, moveStep: 0, towerTouched: null})
            return {pieces: {[towerTouched.key]: piece}, moveStep: 0, towerTouched: null}
        } 
        const from = towerTouched!.key
        let moveStep = [from, key]
        const fitMoves = nextMoves.filter((m: MMRResult) => {
            return moveStep[0] === m.move[0] 
                && m.move.slice(step + 1).includes(moveStep[1]) 
        })
        if (!fitMoves[0]) {
            console.error('cant find', key, from, )
        }
        const { endPos, takenPieces, move } = fitMoves[0]
        const index = move.indexOf(key)
        const lastStep = move.length === index + 1
        if (!lastStep) {
            const gamePiece = {...pos[from]}
            gamePiece.DOM = calcPiecePosition(key, cellsMap, cellSize)
            return {pieces: {[from]: gamePiece}, moveStep: index, towerTouched: null}
        }
        const position = copyObj(endPos) as IBoardPieces
        for (const k in position) {
            if (k === key) {
                position[k].DOM = calcPiecePosition(k, cellsMap, cellSize)
            }
        }
        const makedMove = {
            move: move.join(takenPieces ? TPS : MPS),
            position,
        } as Move
        return {makedMove, towerTouched: null, moveStep: 0}
    }

    onMouseDown(props: IMDownProps) {
        const {
            cellsMap, 
            cellSize, 
            nextMoves,
            moveStep,
            position,
            turn,
            event: {key, x, y}
        } = props
       
        if (!position[key] || position[key].color !== turn) {
            // console.error('ivalid key detection on mouse down')
            return {}
        }
        const { color, type, DOM } = position[key]
        const startCursorPosition = {x, y}
        const possibleMoves = getPiecePossibleMoves(cellsMap, {nextMoves, moveStep} as IGame, key)
        return {
            towerTouched: {
                color, 
                type, 
                key, 
                startTowerPosition: DOM || calcPiecePosition(key, cellsMap, cellSize),
                startCursorPosition,
                possibleMoves 
            }
        }
    }  
}

export const gameHandlers = new TowersGameHandlers()

class PieceSetupHandlers extends EventHandlers{
    onMouseDown(props: IMDownProps) {
        const {
            cellsMap, 
            cellSize,
            position,
            event: {key, x, y}
        } = props
        if (!position[key]) {
            // console.error('ivalid key detection on mouse down')
            return {}
        }
        const { color, type, DOM } = position[key]
        const startCursorPosition = {x, y}
        const possibleMoves = getFreeCells(cellsMap, position)
        return {towerTouched: {
            color, 
            type, 
            key, 
            startTowerPosition: DOM || calcPiecePosition(key, cellsMap, cellSize),
            startCursorPosition,
            possibleMoves 
        }}
    }

    onMouseUp(props: IMUpProps) {
        const {towerTouched, cellSize, cellsMap, event, position} = props
        const { key } = towerTouched
        const boardRect = document.querySelector(`.${BoardCl}`)?.getBoundingClientRect() || {} as any
        if (!boardRect.x) return {
            pieces: {[key]: {...position[key], DOM: calcPiecePosition(key, cellsMap, cellSize)}}
        }
        const possMoves = towerTouched.possibleMoves
        const cellKey = checkMoveTargetCell(event, possMoves, cellSize, boardRect)
        if (!cellKey) return {
            pieces: {[key]: {...position[key], DOM: calcPiecePosition(key, cellsMap, cellSize)}}
        }
        const DOM = calcPiecePosition(cellKey, cellsMap, cellSize)
        let towers = copyObj(position)
        if (key.length > 3) {
            towers[cellKey] = {
                color: towerTouched.color,
                type: towerTouched.type,
                DOM,
            }
            delete towers[key]
            return { towers, towerTouched: null as unknown as TowerTouched }
        } else {
            towers[cellKey] = {...towers[key], DOM}
            delete towers[key]
            return { towers, towerTouched: null as unknown as TowerTouched }
        }
    }
}

export const pieceSetupHandlers = new PieceSetupHandlers()

class TowerSetupHandlers extends EventHandlers{
    onMouseDown(props: IMDownProps) {
        const {
            cellsMap, 
            cellSize, 
            position,
            event: {key, x, y}
        } = props
        const { color, type, DOM } = position[key]
        const startCursorPosition = {x, y}
        const possibleMoves = cellsMap
        return {towerTouched: {
            color, 
            type, 
            key, 
            startTowerPosition: DOM || calcPiecePosition(key, cellsMap, cellSize),
            startCursorPosition,
            possibleMoves 
        }}
    }

    onMouseUp(props: IMUpProps) {
        const {towerTouched, cellSize, position, cellsMap, event} = props
        // const towerTouched = copyObj(towerTouched!) as TowerTouched,
        const boardRect = document.querySelector(`.${BoardCl}`)?.getBoundingClientRect() || {} as any
        const { key } = towerTouched
        if (!boardRect.x) return ({
            pieces: {
                [key]: {...position[key], DOM: calcPiecePosition(key, cellsMap, cellSize)}
            }
        })
        const possMoves = towerTouched.possibleMoves
        const cellKey = checkMoveTargetCell(event, possMoves, cellSize, boardRect)
        const DOM = calcPiecePosition((cellKey || key), cellsMap, cellSize)
        if (!cellKey || cellKey === key) return {
            pieces: {[key]: {...position[key], DOM}}
        }
        let towers = copyObj(position)
        if (position[cellKey] ) {
            return mergeTowers(cellKey, key, towers)
        }
        towers[cellKey] = {
                ...towers[key],
                DOM,
            }
        delete towers[key]
        return { towers, towerTouched: null as unknown as TowerTouched }
    }    
}

export const towerSetupHandlers = new TowerSetupHandlers()

class PieceRemoveHandlers {

    onMouseDown(props: IMDownProps) {
        const {cellsMap, cellSize, position, event: {key}} = props
        let towers = copyObj(position)
        const tower = towers[key]! as IBoardPiece
        const colW = tower.color === PieceColor.white
        const num =  Object.keys(towers).filter(i => i.includes(colW ? "oW" : "oB")).length
        const outboardKey = `${colW ? "oW w" : "oB b"}${num}`
        tower.type = PieceType.man
        tower.DOM = calcPiecePosition(outboardKey, cellsMap, cellSize)
        towers[outboardKey] = tower
        delete towers[key]
        return {pieces: towers}
    }

    onMouseMove(props: any) {
        return {}
    }
    onMouseUp(props: any) {
        return {}
    }
}

class TowerRemoveHandlers {
    onMouseDown(props: IMDownProps) {
        const {cellsMap, cellSize, position, event: {key}} = props
        const towers = copyObj(position) as IBoardPieces
        const { white, black } = towers[key]
        const unusedTowers = getNumbersOfUnusedTowers(towers)
        delete towers[key]
        for (let i = 0; i < white; i++) {
            const key = `oW w${unusedTowers.white + i}`
            const DOM = calcPiecePosition(key, cellsMap, cellSize)
            towers[key] = {
                color: PieceColor.white,
                white: 1,
                black: 0,
                type: PieceType.man,
                DOM,
            }
        }
        for (let i = 0; i < black; i++) {
            const key = `oB b${unusedTowers.black + i}`
            const DOM = calcPiecePosition(key, cellsMap, cellSize)
            towers[key] = {
                color: PieceColor.black,
                white: 0,
                black: 1,
                type: PieceType.man,
                DOM,
            }
        }
        return {towers}
    }

    onMouseMove(props: any) {
        return {}
    }

    onMouseUp(props: any) {
        return {}
    }
}

class KingSetHandlers {
    onMouseDown(props: IMDownProps) {
        const {position, event: {key}} = props
        const towers = copyObj(position)
        const tower = towers[key] as IBoardPiece
        tower.type = tower.type === PieceType.king ? PieceType.man : PieceType.king
        return {pieces: {[key]: tower}}
    }

    onMouseMove(props: any) {
        return {}
    }

    onMouseUp(props: any) {
        return {}
    }
}

export const kingSetHandlers = new KingSetHandlers()

export const towerRemoveHandlers = new TowerRemoveHandlers()

export const pieceRemoveHandlers = new PieceRemoveHandlers()
