import { useDispatch, useSelector} from "react-redux"
import { TiLightbulb } from "react-icons/ti";
import { MdOutlineAutorenew } from "react-icons/md";
import { help } from '../../store/gameSlice'
import { turnBoard } from "../../store/boardSlice";
import { selectBoardWithProps, selectPuzzle, selectUserWithProps, selectWithProps } from "../../store/rootState&Reducer";

import { IPuzzle, IRootState, ITopState } from "../../models/models";
import { CgMenuGridR } from "react-icons/cg";
import { TbPuzzle } from "react-icons/tb";
import { choosePuzzle } from "../../store/topStateSlice";
import { useNavigate } from "react-router-dom";
import { TopState } from "../../constants/gameConstants";
import { useEffect, useState } from "react";



export const PuzzleMenu = () => {
    const {reversedBoard: revBoard} = useSelector(selectBoardWithProps(['reversedBoard']))
    const {puzzleResolved: passed} = useSelector(selectWithProps(['puzzleResolved'], TopState)) as ITopState
    const {exp: {onboardingPassed}} = useSelector(selectUserWithProps(['exp']))
    const puzzle = useSelector(selectPuzzle)
    const {puzzles: ps, selectedPuzzle} = useSelector(
        selectWithProps(['puzzles', 'selectedPuzzle'], TopState)
    ) as ITopState
    const [diasbled, setDiasbled] = useState(passed && !passed.animated)
    const puzzles = ps.slice().sort((a: IPuzzle, b: IPuzzle) => a.level - b.level)
    const navi = useNavigate()
    const dispatch = useDispatch()

    const hintMove = useSelector((state: IRootState) => state.game.hintMove)
    const noHint = !hintMove && puzzle?.level > 6

    useEffect(() => {
        if (!passed) return
        setDiasbled(!passed.animated)
    }, [passed])

    const handleBack = () => {
        if (diasbled) return
        dispatch(choosePuzzle(''))
    }
    const handleNext = () => {
        if (diasbled) return
        const next = (puzzles.findIndex((p: IPuzzle) => p._id === selectedPuzzle) + 1) 
            % (puzzles.length)
        dispatch(choosePuzzle(puzzles[next]._id))
        navi(`../${puzzles[next]._id}`)
    }
    const turnBoardOver = () => {
        if (diasbled) return
        dispatch(turnBoard(!revBoard))
    }

    const handleHelp = () => {
        if (noHint || diasbled) return
        dispatch(help(true))
    }

    const hintClass = `puzzle-menu__item hint${noHint || diasbled  ? ' disabled' : ''}${puzzle?.level <= 3 ? ' anim' : ''}`
    const liClass = `puzzle-menu__item${diasbled ? ' disabled' : ''}`
    return (
        <ul className="puzzle-menu">
            {
                onboardingPassed 
                ? <>
                        <li
                            className={liClass}
                            onClick={handleBack}
                            role="button"
                            title="back to puzzles menu"
                        >
                            <CgMenuGridR />
                        </li>
                        <li
                            className={liClass}
                            onClick={handleNext}
                            role="button"
                            title="to next puzzle"
                        >
                            <TbPuzzle />
                        </li>
                    </>
                : null
            }
            <li 
                title="turn board" 
                role="button" 
                className={`${liClass} revert`}
                onClick={turnBoardOver}
            >
                <MdOutlineAutorenew />
            </li>
            {
                onboardingPassed
                ? null
                : <li 
                    title="hint" 
                    role="button" 
                    className={hintClass} 
                    onClick={handleHelp}
                >
                    <TiLightbulb />
                </li>
            }
                            
        </ul>
    )
    
}
